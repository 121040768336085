import React, { useRef } from "react";
import Link from "next/link";
import { StyleClass } from "primereact/styleclass";
import { AuthServices } from "@/services/auth.services";
import RecruiterMenu from "./RecruiterMenu";
import TalentMenu from "./TalentMenu";
import NotificationsMenu from "./NotificationsMenu";
import MessagesMenu from "./MessagesMenu";
import UserMenu from "./UserMenu";
import { useUser } from "@/context/AuthContext";
import { IProfile, IRecruiter } from "@/types/formInfo.interface";
import { TNotification } from "@/types/notification.interface";
import HelpMenu from "./HelpMenu";

interface LoggedInMenuProps {
  completedProfile: boolean;
  accessToHeaderMenu: boolean;
  unreadNotifications: TNotification[];
  readNotifications: () => void;
  showMessage: boolean;
  messageNotRead: number;
  userData: IProfile | IRecruiter | undefined;
}

const LoggedInMenu: React.FC<LoggedInMenuProps> = ({
  completedProfile,
  accessToHeaderMenu,
  unreadNotifications,
  readNotifications,
  showMessage,
  messageNotRead,
  userData,
}) => {
  const { currentUser } = useUser();

  const btnRef1 = useRef(null);

  return (
    <div className="w-full ml-auto">
      <div className="flex flex-row-reverse align-items-center">
        <StyleClass
          nodeRef={btnRef1}
          selector="@next"
          enterClassName="hidden"
          leaveToClassName="hidden"
          hideOnOutsideClick
        >
          <a
            ref={btnRef1}
            className="cursor-pointer block lg:hidden text-right text-700"
          >
            <i className="pi pi-bars text-4xl"></i>
          </a>
        </StyleClass>
        <div className="flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-3 shadow-2 lg:shadow-none">
          {/* Conditional Menus */}
          {currentUser?.userType === "recruiter" &&
            completedProfile &&
            accessToHeaderMenu && <RecruiterMenu />}

          {currentUser?.userType === "talent" && completedProfile && (
            <TalentMenu />
          )}

          {!completedProfile && <div></div>}

          <ul className="list-none p-0 m-0 flex select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none ml-auto">
            {completedProfile ? (
              <>
                <div className="hidden lg:flex">
                  {currentUser?.userType === "talent" && <HelpMenu />}
                  <NotificationsMenu
                    unreadNotifications={unreadNotifications}
                    readNotifications={readNotifications}
                  />
                  {showMessage && (
                    <MessagesMenu messageNotRead={messageNotRead} />
                  )}
                </div>
                <UserMenu userData={userData} />
              </>
            ) : (
              <li
                className="inline-flex cursor-pointer text-primary-600 text-700 hover:text-primary p-3 md:p-0"
                onClick={() => AuthServices.logOut()}
              >
                Sign out
              </li>
            )}
          </ul>
        </div>
        <div className="inline-block lg:hidden">
          {completedProfile && (
            <>
              {currentUser?.userType === "talent" && <HelpMenu />}

              {showMessage && (
                <MessagesMenu messageNotRead={messageNotRead} iconOnly />
              )}
              <NotificationsMenu
                unreadNotifications={unreadNotifications}
                readNotifications={readNotifications}
                iconOnly
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoggedInMenu;
