import React from "react";
import Link from "next/link";
import { Button } from "primereact/button";

const HelpMenu = () => {
  return (
    <li className="inline-flex relative">
      <Button
        className="bg-transparent border-none p-0"
        tooltip="Help"
        tooltipOptions={{ position: "top" }}
      >
        <Link
          href="/how-to"
          className="h-full no-underline flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors w-full text-primary-500"
        >
          <i className="pi pi-question-circle text-xl lg:text-2xl mr-2 lg:mr-0 p-overlay-badge text-primary-500" />
        </Link>
      </Button>
    </li>
  );
};

export default HelpMenu;
