"use client";
import React from "react";
import { useUser } from "@/context/AuthContext";

import Link from "next/link";

import Logo from "@/assets/logo/logo.png";

import { useMessageData } from "@/hooks/useMessageData";
import { useUserData } from "@/hooks/useUserData";

import { useFeatureAccess } from "@/hooks/useFeatureAccess";
import { IAccessLevelRecruiter } from "@/types/formInfo.interface";
import useNotifications from "@/services/hooks/notifications";
import { trackClickSignIn, trackClickSignUp } from "@/event/mixpanel";
import HeaderSkeleton from "./HeaderSkeleton";

import SignInSignUpLinks from "./SignInSignUpLinks";
import LoggedInMenu from "./LoggedInMenu";

const Header = () => {
  //Hooks
  const { isLogin, currentUser } = useUser();
  const { userData, completedProfile, loading } = useUserData(
    currentUser?.uid,
    currentUser?.userType
  );
  const { messageNotRead } = useMessageData(currentUser?.uid);
  const { unreadNotifications, readNotifications } = useNotifications();

  const { hasAccess: accessToMessaging } = useFeatureAccess([
    process.env.recruiterFullAccessLevel,
  ] as IAccessLevelRecruiter[]);

  const { hasAccess: accessToHeaderMenu } = useFeatureAccess([
    process.env.recruiterFullAccessLevel,
    process.env.recruiterTrialAccessLevel,
  ] as IAccessLevelRecruiter[]);

  // Const
  const showMessage = accessToMessaging || currentUser?.userType === "talent";

  return (
    <div
      className="surface-overlay px-2 md:px-6 shadow-2 flex justify-content-between align-items-center relative lg:static"
      style={{ minHeight: "80px" }}
    >
      {/* Logo Section */}
      <div className="flex h-full align-items-between">
        <Link href="/" className="mr-5">
          <img src={Logo.src} alt="logo" className="w-7rem md:w-10rem" />
        </Link>
      </div>

      {loading ? (
        <>{!isLogin && <HeaderSkeleton />}</>
      ) : isLogin ? (
        <LoggedInMenu
          completedProfile={completedProfile}
          accessToHeaderMenu={accessToHeaderMenu}
          unreadNotifications={unreadNotifications}
          readNotifications={readNotifications}
          showMessage={showMessage}
          messageNotRead={messageNotRead}
          userData={userData}
        />
      ) : (
        <SignInSignUpLinks
          trackClickSignIn={trackClickSignIn}
          trackClickSignUp={trackClickSignUp}
        />
      )}
    </div>
  );
};

export default Header;
